<template>
  <div class="order-page container-fluid">

    <div class="mt-3">
      <router-link to="/place/map" class="button-back-empty">
        <span class="fa fa-arrow-left"></span>
      </router-link>
    </div>

    <div class="order-list" v-if="orderList.length > 0">
      <div class="card rounded-3" v-for="orderData in orderList" :key="orderData.id" @click="doOrderView(orderData)">
        <div class="card-body">
          <span class="price-block">
            <span class="fa fa-circle-o"></span>
            {{ this.getTotalAmount(orderData.price, orderData.count, orderData.payAmount) }}{{ this.getCurrencySymbol() }}
          </span>
          <span class="status-block" :class="'status-block-' + orderData.status">
             <span v-if="orderData.status == orderStatusEnum.ready">
              payment required
            </span>
            <span v-else-if="orderData.status == orderStatusEnum.open">
              training started <br />
            </span>
            <span v-else-if="orderData.status == orderStatusEnum.stop">
              payment wating <br />
            </span>
            <span v-else-if="orderData.status == orderStatusEnum.close">
              training is completed
            </span>
            <span v-else-if="orderData.status == orderStatusEnum.reject">
              training canceled
            </span>
          </span>
          <div class="order">Order #{{ orderData.id }}</div>
          <div class="title">{{ orderData.placeData?.title }}</div>
          <div class="work-time" v-if="orderData.placeData && orderData.placeData.workTime" v-html="this.decode(this.getFormatText(orderData.placeData?.workTime))"></div>
          <div class="address" v-if="orderData.placeData && orderData.placeData.address" v-html="orderData.placeData?.address"></div>
          <div class="date pb-3">
            <div v-if="orderData.dateStart">
              Start time:
              {{ this.getDateTime(orderData.dateStart) }}
            </div>
            <div v-else>
              Date:
              {{ this.getDateTime(orderData.dateCreate) }}
            </div>
            <div v-if="orderData.dateFinish">
              End time:
              {{ this.getDateTime(orderData.dateFinish) }}
            </div>
          </div>

        </div>
      </div>
    </div>
    <div v-else class="text-center">
        No records found
    </div>

  </div>
</template>

<script>
import OrderMixin from '@/modules/order/mixins/OrderMixin';
import BaseMixin from "@/modules/base/mixins/BaseMixin";
import {OrderStatusEnum} from '@/modules/order/enums/OrderEnum';

export default {

  mixins: [OrderMixin, BaseMixin],

  data: () => ({
    orderList: [],

    orderStatusEnum: OrderStatusEnum
  }),

  async mounted() {

    if (!await this.checkUser())
      return;

    await this.doOrderIndex();
  },

  methods: {

    async doOrderIndex() {

      let apiHolder = await this.sendApiGet(['api/order/index']);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.orderList)
        this.orderList = apiHolder.data.orderList;
    },

    async doOrderView(orderData) {

      await this.$router.push({path: '/order/view/' + orderData.id, query: {back: 'list'}});
    }

  }

}
</script>

<style scoped>

.order-list {
  padding-top: 30px;
  padding-bottom: 20px;
}

.order-list .card {
  background: #343434;
  color: #FFF;
  margin-bottom: 30px !important;
  cursor: pointer;
}

.order-list .card-body {
  padding-top: 5px;
  padding-bottom: 10px;
  position: relative;
}

.order-list .card .price-block {
  background: #FFFA83;
  position: absolute;
  top: -15px;
  left: 15px;
  line-height: 1.5em;
  font-size: 14px;
  color: #000;
  font-weight: 700;
  border-radius: 38px;
  padding: 4px 10px 4px 5px;
  display: flex;
}

.order-list .card .price-block .fa {
  font-size: 20px;
  padding-right: 8px;
}

.order-list .card .status-block {
  background: #2CDAE5;
  position: absolute;
  top: -15px;
  right: 15px;
  line-height: 1.5em;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  border-radius: 38px;
  padding: 4px 15px 4px 15px;
  display: flex;
}

.order-list .card .status-block-ready,
.order-list .card .status-block-open {
  background: #FF007A;
}

.order-list .card .title {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 20px;
}

.order-list .card .order {
  color: #EBEAEC;
  font-size: 13px;
  float: right !important;
  font-weight: 500;
  padding-top: 20px;
}

.order-list .card .work-time {
  color: #FFFA83;
  font-weight: 500;
}

</style>